import React from 'react'
import Poppies from './Poppies'
import './style/index.css'

function App() {
  return (
    <Poppies/>
  );
}

export default App;